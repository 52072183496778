<template>
	<div :style="Bg">
		<Header :title="$t('google.codeVerifyTitle')"></Header>
		
		<div class="column-center-content main-content" style="margin-top: 80px;">
			<label style="padding: 10px 20px; color: #999999; font-size: 14px; width: 100%;">将发送验证码到
				<span v-text="phone"></span>
			</label>
			<div style="padding: 0px 20px 10px 20px; width:100%;">
				<div class="row-content" style="background-color: #F2F2F2; height: 40px; width: 100%; padding: 0px 0px 0px 10px; display: flex; justify-content: space-between; border-radius: 5px;">
					<v-text-field style="padding-top: 15px;" :placeholder="$t('login.tac_hint')" color="success" v-model="tacCode"></v-text-field>
					<mt-button @click="sendTacCode" type="danger" plain style="font-size: 16px; height: 40px; border: none; border-radius: 15px; color: #009245; font-weight: bold; width: 100px;">{{$t('login.getTac')}}</mt-button>
				</div>
			</div>
			
			<label style="padding: 10px 20px; color: #999999; font-size: 14px; width: 100%;">输入Google验证码</label>
			<div style="padding: 0px 20px 10px 20px; width:100%;">
				<div class="row-content" style="background-color: #F2F2F2; height: 40px; width: 100%; padding: 0px 0px 0px 10px; display: flex; justify-content: space-between; border-radius: 5px;">
					<v-text-field style="padding-top: 15px;" :placeholder="$t('google.googleCode_hint')" color="success" v-model="googleCode"></v-text-field>
					<!-- <mt-button @click="doStickup" type="danger" plain style="font-size: 16px; height: 40px; border: none; border-radius: 15px; color: #009245; font-weight: bold; width: 100px;">{{$t('google.stickup')}}</mt-button> -->
				</div>
			</div>
			
			<div class="btn-content" style="margin-bottom: 0px; margin-top: 60px;">
				<v-btn rounded block @click="goNext" style="background-color: #009245; color: white; height: 40px;">
					提交
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../../components/NavHeader.vue'
	import { Toast, Indicator } from 'mint-ui';
	
	export default{
		name: "BackupVerification",
		components: {
			Header
		},
		data:() => ({
			Bg : {
				height: "100vh",
				width: "100%",
				backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			},
			phone: JSON.parse(localStorage.getItem('userInfo')).phone,
			tacCode: '',
			googleCode: ''
		}),
		methods: {
			sendTacCode() {
				let phone = JSON.parse(localStorage.getItem('userInfo')).phone;
				
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				let params = {
					phone: phone
				}
				this.$request.sendCode(
					params
				).then(()=>{
					Indicator.close();
					Toast({
						message: 'Success',
						position: 'bottom'
					});
				});
			},
			
			doStickup() {
				
			},
			
			goNext() {				
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				let params = {
					code: this.tacCode,
					newPassword: this.googleCode
				}
								
				this.$request.authPassword(params).then(()=>{
					Indicator.close();
					Toast({
						message: 'Success',
						position: 'bottom'
					});
					
					this.$router.push({
						"name": "Main"
					})
				});
			}
		}
	}
</script>

<style>
</style>
